// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start();
require("channels");

import "bootstrap";
import "../stylesheets/application";
import "lazysizes";

window.$ = require("jquery");
window.jQuery = window.$;

let initDocument = () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  $(".js-link-read-more").click(function () {
    location.href = $(this).find("a.read-more").attr("href");
  });

  $(".js-linked").click(function () {
    location.href = $(this).data("url");
  });
};

let pageChange = () => {
  var url = window.location.href;

  dataLayer.push({
    event: "pageView",
    virtualUrl: url,
  });
};

$(document).on("page:change", pageChange);
$(document).on("turbolinks:load", initDocument);
$(document).on("render_async_load", initDocument);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
